import { FC } from "react";
import styles from "./AppBar.module.css";

interface AppBarProps {}

export const AppBar: FC<AppBarProps> = () => {
  if (!window.Telegram.WebApp.isFullscreen) return null;

  return (
    <div className={styles.appBar}>
      <p className={styles.appTitle}>
        Court <span>FL</span>ow
      </p>
    </div>
  );
};
