import { useEffect, useState } from "react";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useData } from "./providers/DataProvider";
import { Input } from "./components/Input";
import { Search, SearchItem } from "./components/Search";
import { LoadingScreen } from "./components/LoadingScreen";
import { NoAuthDataScreen } from "./components/NoAuthDataScreen";
import { ObserverList } from "./components/ObserverList";
import { Switch } from "./components/Swich";
import { CaseTabs } from "./components/CaseTabs";
import { Section } from "./components/Section";
import { SafeArea } from "./components/SafeArea";
import { Page } from "./components/Page";
import { AppBar } from "./components/AppBar";
import { Court, Hall } from "./shared/types";
import { ReactComponent as ArrowRightIcon } from "./shared/assets/arrow-right.svg";
import styles from "./App.module.css";

function App() {
  const {
    isLoading,
    isAuth,
    courts,
    halls,
    currentCourt,
    currentHall,
    updateCourt,
    updateHall,
    observe,
    cancelObserve,
    observerList,
  } = useData();

  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isObserverListActive, setIsObserverListActive] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchItems, setSearchItems] = useState<SearchItem[]>([]);
  const [isCurrentHallObserved, setIsCurrentHallObserved] = useState(false);
  const [, , selection] = useHapticFeedback();

  useEffect(() => {
    const webApp = window.Telegram.WebApp;

    if (webApp.platform !== "tdesktop" && !webApp.isFullscreen) {
      try {
        webApp.requestFullscreen();
        webApp.expand();
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  useEffect(() => {
    if (observerList.find((observer) => observer.hall === currentHall?.value)) {
      setIsCurrentHallObserved(true);
    } else {
      setIsCurrentHallObserved(false);
    }
  }, [observerList, currentHall]);

  const handleSearchCourt = () => {
    setSearchTitle("Поиск суда");

    setSearchItems(
      courts.map((court) => ({
        title: court.title,
        value: court.value,
        type: "court",
      }))
    );

    selection();
    setIsSearchActive(true);
  };

  const handleSearchHall = () => {
    setSearchTitle("Поиск зала");

    setSearchItems(
      halls.map((hall) => ({
        title: hall.title,
        value: hall.value,
        type: "hall",
      }))
    );

    selection();
    setIsSearchActive(true);
  };

  const handleCloseSearch = () => {
    setIsSearchActive(false);
    setSearchTitle("");
    setSearchItems([]);
  };

  const handleSearchChange = (item: SearchItem) => {
    if (item.type === "hall") {
      updateHall(item as unknown as Hall);
    }
    if (item.type === "court") {
      updateCourt(item as unknown as Court);
    }
    handleCloseSearch();
  };

  const handleObserverListClicked = () => {
    selection();
    setIsObserverListActive(true);
  };

  const handleObserverListClose = () => {
    setIsObserverListActive(false);
  };

  const handleObserve = () => {
    if (!currentCourt || !currentHall) return;

    if (isCurrentHallObserved) {
      const observer = observerList.find((observer) => observer.hall === currentHall.value);
      if (observer) {
        cancelObserve(observer.id);
      }
    } else {
      observe(currentCourt.value, currentHall.value);
    }
  };

  if (isLoading) {
    return (
      <Page>
        <AppBar />
        <SafeArea>
          <LoadingScreen />
        </SafeArea>
      </Page>
    );
  }

  if (!isAuth) {
    return (
      <Page>
        <AppBar />
        <NoAuthDataScreen />
      </Page>
    );
  }

  const isObserveButtonDisabled = isCurrentHallObserved ? false : !currentHall || observerList.length > 2;

  return (
    <Page>
      <AppBar />
      <SafeArea>
        <div className={styles.content}>
          <Section>
            <Input
              label={"Суд"}
              placeholder={"Выберите суд"}
              value={currentCourt?.title}
              onChange={handleSearchCourt}
            />
            <Input
              label={"Зал"}
              placeholder={"Выберите зал заседаний"}
              value={currentHall?.title}
              onChange={handleSearchHall}
            />
          </Section>

          <Section>
            <div className={styles.menu}>
              <div
                style={{ cursor: "pointer" }}
                className={styles.menuItem}
                onClick={handleObserverListClicked}
              >
                <p>Мой мониторинг</p>
                <ArrowRightIcon
                  className={styles.rowIcon}
                  width={25}
                  height={25}
                  color={"var(--tg-theme-hint-color)"}
                />
              </div>
              <div className={styles.menuItem}>
                <p>Уведомлять об изменениях</p>
                <Switch
                  disabled={isObserveButtonDisabled}
                  checked={isCurrentHallObserved}
                  onChange={handleObserve}
                />
              </div>
            </div>
          </Section>
        </div>

        <CaseTabs />

        <Search
          isActive={isSearchActive}
          title={searchTitle}
          items={searchItems}
          onChange={handleSearchChange}
          onClose={handleCloseSearch}
        />

        <ObserverList isActive={isObserverListActive} onClose={handleObserverListClose} />
      </SafeArea>
    </Page>
  );
}

export default App;
